import { SendIcon } from '@bws-bitfy/icons-react';
import { Grid } from '@mui/material';
import Button from 'components/atoms/Button';
import If from 'components/atoms/If';
import TextField from 'components/atoms/TextField';
import { useFormik } from 'formik';
import { HookCallbackFunctions } from 'hooks/types';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { IContactMessage } from 'services/contactUs';
import * as Yup from 'yup';

interface ContactMessageFormProps {
  initialValues: IContactMessage;
  loading: boolean;
  setIsFormValid?: (isValid: boolean) => void;
  onSubmit: (
    values: IContactMessage,
    callbacks?: HookCallbackFunctions
  ) => void;
  hideSendButton?: boolean;
  trackingTitle?: string;
  formId?: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  email: Yup.string()
    .required('E-mail is required.')
    .email('E-mail is not valid.'),
  phone: Yup.string().required('Telephone is required.'),
  message: Yup.string().required('Message is required.')
});

const ContactMessageForm: React.FC<ContactMessageFormProps> = ({
  onSubmit,
  loading,
  initialValues,
  hideSendButton,
  setIsFormValid,
  trackingTitle,
  formId
}) => {
  const { t } = useTranslation('webSiteEn');
  const formik = useFormik<IContactMessage>({
    initialValues,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values, {
        onSuccess: () => {
          mixpanel.track(`${trackingTitle}_success_send_toast`);
          formik.resetForm();
        }
      });
    }
  });

  useEffect(() => {
    if (!!setIsFormValid) {
      setIsFormValid(formik.isValid);
    }
  }, [formik]);

  return (
    <form id={formId ?? 'need-help-form'} onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            id="name"
            data-testid="name-input"
            label={t('webSiteEn.Contact.form.name')}
            placeholder={t('webSiteEn.Contact.form.name')}
            data-mixpanel="homepage_forms_name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email"
            data-testid="email-input"
            label={t('webSiteEn.Contact.form.email')}
            data-mixpanel={`${trackingTitle}_forms_email`}
            value={formik.values.email}
            placeholder={t('webSiteEn.Contact.form.email')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberFormat
            label={t('webSiteEn.Contact.form.phone')}
            id="phone"
            placeholder={t('webSiteEn.Contact.form.phone')}
            data-mixpanel={`${trackingTitle}_forms_telephone`}
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            customInput={TextField}
            format="(##) # ####-####"
            allowNegative={false}
            allowedDecimalSeparators={['+']}
            decimalSeparator="+"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="message"
            label={t('webSiteEn.Contact.form.message')}
            multiline
            maxRows={4}
            inputProps={{
              style: {
                minHeight: 71,
                maxHeight: 120
              },
              'data-mixpanel': `${trackingTitle}_forms_message`
            }}
            value={formik.values.message}
            size="small"
            placeholder={t('webSiteEn.Contact.form.messagePlaceholder')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
        </Grid>
      </Grid>

      <If condition={!hideSendButton}>
        <Button
          loading={loading}
          type="submit"
          data-testid="send-button"
          data-mixpanel={`${trackingTitle}_forms_send`}
          fullWidth
          startIcon={!loading && <SendIcon />}
          variant="contained"
          sx={{
            marginTop: '3.125rem'
          }}
          size="large"
          disabled={!formik.isValid || loading}
        >
          {t('webSiteEn.Contact.form.button')}
        </Button>
      </If>
    </form>
  );
};

export default ContactMessageForm;
