/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { BlockchainNetworkDTO } from 'models/TokenContractDTO';
import APIService, { BaaSServerResponse } from 'services/api/APIService';

export interface IContactMessage {
  email: string;
  name: string;
  phone: string;
  message: string;
}

export async function sendContactMessage(
  contactMessage: IContactMessage,
  reCaptchaToken?: string
): Promise<BaaSServerResponse<BlockchainNetworkDTO>> {
  const headers = reCaptchaToken
    ? {
        'x-recaptcha-token': reCaptchaToken
      }
    : null;
  return APIService.getInstance({ api: 'dashboard' })
    .post(
      `/dashboard/contact`,
      {
        ...contactMessage
      },
      {
        headers
      }
    )
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response?.data;
    });
}
