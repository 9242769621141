import {
  Box,
  InputBase,
  InputBaseProps,
  InputLabel,
  useTheme
} from '@mui/material';
import Text from 'components/atoms/Text/Text';
import { TranslationValuePath } from 'i18n';
import mixpanel from 'mixpanel-browser';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';

export interface TextFieldProps extends InputBaseProps {
  characterLimiter?: number;
  label?: string;
  'data-mixpanel'?: string;
  helperText?: string;
  helperTextIntl?: TranslationValuePath | string;
  value?: string | number;
  maskChar?: string;
  formatChars?: object;
  alwaysShowMask?: boolean;
  mask?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  id?: string;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  disabledOpacity?: number;
  colorCustom?: string;
  actionText?: string;
  focused?: boolean;
  actionOnClick?: () => void;
}
const TextField: React.FC<TextFieldProps> = ({
  helperText,
  helperTextIntl,
  characterLimiter,
  label,
  mask,
  maskChar,
  alwaysShowMask,
  id,
  autoComplete = 'off',
  colorCustom,
  formatChars,
  disabledOpacity,
  actionText,
  actionOnClick,
  focused,
  ...props
}) => {
  const theme = useTheme();
  const [componentfocused, setComponentFocused] = useState(focused || false);
  return (
    <Box flex={1}>
      <Box display="flex" justifyContent="space-between">
        {label && (
          <InputLabel
            data-testid="tfield-label"
            disabled={props.disabled}
            shrink
            focused={componentfocused}
            sx={{
              '&.Mui-focused': {
                color: props.error
                  ? theme.palette.error.light
                  : theme.palette.primaryOverlays[200]
              },
              color: props.error
                ? theme.palette.error.light
                : theme.palette.text.primary,
              fontWeight: 500
            }}
          >
            {label}
          </InputLabel>
        )}
        {actionText && (
          <Text
            onClick={actionOnClick}
            sx={{
              fontSize: '14px',
              color: 'primaryOverlays.200',
              cursor: 'pointer'
            }}
          >
            {actionText}
          </Text>
        )}
      </Box>

      {props.multiline ? (
        <InputBase
          autoComplete={autoComplete}
          disabled={props.disabled}
          id={id}
          fullWidth
          data-mixpanel={props?.['data-mixpanel']}
          onFocus={(event) => {
            mixpanel.track(
              event.currentTarget?.getAttribute('data-mixpanel') ?? ''
            );
            if (props?.onFocus) {
              props.onFocus(event);
            }
          }}
          inputProps={{
            maxLength: characterLimiter,
            ...props.inputProps,
            'data-testid': 'tfield-value',
            'data-mixpanel': props?.['data-mixpanel'],
            id
          }}
          {...props}
          sx={{
            '& .MuiInputBase-input': {
              color: 'text.primary',
              fontSize: '0.875rem', // 14px
              boxSizing: 'border-box',
              position: 'relative',
              borderRadius: '12px',
              backgroundColor: colorCustom
                ? `${theme.palette.cryptos[colorCustom]}66`
                : 'divider',
              margin: 0,
              opacity: props.disabled ? '0.55' : '1',
              paddingY: props.size === 'small' ? 2 : 2.75,
              paddingRight: theme.spacing(props.endAdornment ? 6 : 2),
              paddingLeft: theme.spacing(props.startAdornment ? 6 : 2),
              border: props.error
                ? `2px solid ${theme.palette.error.light}`
                : `2px solid transparent`,
              transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow'
              ]),
              '&:focus': {
                // eslint-disable-next-line no-nested-ternary
                border: colorCustom
                  ? `2px solid ${colorCustom}`
                  : props.error
                  ? `2px solid ${theme.palette.error.light}`
                  : `2px solid ${theme.palette.primaryOverlays[200]}`,
                color: 'white'
              },
              '&:hover': {
                backgroundColor: colorCustom
                  ? `${theme.palette.cryptos[colorCustom]}66`
                  : theme.palette.primaryOverlays.A200
              },
              '&:error': {
                border: `2px solid red !important`,
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: `2px solid red !important`
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid red !important`
                }
              },
              '&.Mui-disabled': {
                opacity: disabledOpacity
              }
            }
          }}
          startAdornment={
            <Box
              sx={{
                svg: {
                  fill: props.error
                    ? theme.palette.error.light
                    : theme.palette.iconPrimary,
                  opacity: props.disabled ? '0.33' : '1'
                }
              }}
              position="absolute"
              pl={2}
              pt="5px"
            >
              {props.startAdornment}
            </Box>
          }
          endAdornment={
            <Box
              sx={{
                svg: {
                  fill: props.error
                    ? theme.palette.error.light
                    : theme.palette.iconPrimary,
                  opacity: props.disabled ? '0.33' : '1'
                }
              }}
              position="absolute"
              display="flex"
              alignItems="center"
              right="8px"
            >
              {props.endAdornment}
            </Box>
          }
        />
      ) : (
        <InputMask
          maskChar={maskChar || ''}
          alwaysShowMask={alwaysShowMask}
          mask={mask || ''}
          onFocus={(event) => {
            mixpanel.track(
              event.currentTarget?.getAttribute('data-mixpanel') ?? ''
            );
            setComponentFocused(true);
          }}
          onBlurCapture={() => setComponentFocused(false)}
          formatChars={formatChars}
          autoComplete={autoComplete}
          {...props}
        >
          {(inputProps) => {
            return (
              <InputBase
                autoComplete={autoComplete}
                {...inputProps}
                disabled={props.disabled}
                id={id}
                fullWidth
                data-testid="tfield-input"
                inputProps={{
                  maxLength: characterLimiter,
                  ...props.inputProps,
                  'data-testid': 'tfield-value',
                  'data-mixpanel': props?.['data-mixpanel'],
                  id
                }}
                {...props}
                sx={{
                  'input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0
                  },
                  'input::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0
                  },
                  '& .MuiInputBase-input': {
                    color: 'text.primary',
                    fontSize: '0.875rem', // 14px
                    boxSizing: 'border-box',
                    position: 'relative',
                    borderRadius: '12px',
                    backgroundColor: colorCustom
                      ? `${theme.palette.cryptos[colorCustom]}66`
                      : 'divider',
                    margin: 0,
                    opacity: props.disabled ? '0.55' : '1',
                    paddingY: props.size === 'small' ? 2 : 2.75,
                    paddingRight: theme.spacing(props.endAdornment ? 6 : 2),
                    paddingLeft: theme.spacing(props.startAdornment ? 6 : 2),
                    border: props.error
                      ? `2px solid ${theme.palette.error.light}`
                      : `2px solid transparent`,
                    transition: theme.transitions.create([
                      'border-color',
                      'background-color',
                      'box-shadow'
                    ]),
                    '&:focus': {
                      // eslint-disable-next-line no-nested-ternary
                      border: colorCustom
                        ? `2px solid ${colorCustom}`
                        : props.error
                        ? `2px solid ${theme.palette.error.light}`
                        : `2px solid ${theme.palette.primaryOverlays[200]}`,
                      color: 'white'
                    },
                    '&:hover': {
                      backgroundColor: colorCustom
                        ? `${theme.palette.cryptos[colorCustom]}66`
                        : theme.palette.primaryOverlays.A200
                    },
                    '&:error': {
                      border: `2px solid red !important`,
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: `2px solid red !important`
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid red !important`
                      }
                    },
                    '&.Mui-disabled': {
                      opacity: disabledOpacity
                    }
                  }
                }}
                startAdornment={
                  <Box
                    sx={{
                      svg: {
                        fill: props.error
                          ? theme.palette.error.light
                          : theme.palette.iconPrimary,
                        opacity: props.disabled ? '0.33' : '1'
                      }
                    }}
                    position="absolute"
                    pl={2}
                    pt="5px"
                  >
                    {props.startAdornment}
                  </Box>
                }
                endAdornment={
                  <Box
                    sx={{
                      svg: {
                        fill: props.error
                          ? theme.palette.error.light
                          : theme.palette.iconPrimary,
                        opacity: props.disabled ? '0.33' : '1'
                      }
                    }}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    right="8px"
                  >
                    {props.endAdornment}
                  </Box>
                }
              />
            );
          }}
        </InputMask>
      )}

      <Box position="relative">
        <Text
          data-testid="tfield-helper-text"
          variant="caption"
          sx={{
            position: 'absolute',
            paddingLeft: 2,
            paddingTop: '4px',
            color: props.error
              ? theme.palette.error.light
              : theme.palette.helperText
          }}
          intlPath={helperTextIntl as TranslationValuePath}
        >
          {helperText}
        </Text>

        {characterLimiter && (
          <Text
            data-testid="tfield-character-limiter"
            variant="caption"
            sx={{
              position: 'absolute',
              right: 0,
              paddingRight: 2,
              paddingTop: '4px',
              color: props.error
                ? theme.palette.error.light
                : theme.palette.helperText
            }}
          >
            {(props.value as string)?.length || '0'}/{characterLimiter}
          </Text>
        )}
      </Box>
    </Box>
  );
};
export default TextField;
